<template>
  <b-modal
    id="add-feedback-popup"
    modal-class="modal-add"
    :static="true"
    aria-labelledby="add-feedback-popup___BV_modal_title_"
  >
    <div slot="modal-header">
      <h5 class="modal-add-title" id="add-feedback-popup___BV_modal_title_">
        Підтримка
      </h5>
      <p class="modal-add-description">
        Виберіть зручний для себе месенджер, щоб звернутись у підтримку.
      </p>
    </div>

    <div class="d-flex flex-column align-items-center mb-3">
      <div>
        <a
          href="viber://chat?number=%2B380933140747"
          v-if="!this.$route.meta.menu"
          target="_blank"
          class="viber-item-button add-item-button"
        >
          <svg class="icon viber-logo">
            <use xlink:href="@/assets/icons/viber.svg#viber"></use>
          </svg>
          Viber
        </a>
        <a
          href="tg://resolve?domain=EasyBaseDnepr"
          v-if="!this.$route.meta.menu"
          target="_blank"
          class="viber-item-button add-item-button ml-3"
        >
          <svg class="icon viber-logo">
            <use xlink:href="@/assets/icons/telegram.svg#telegram"></use>
          </svg>
          Telegram
        </a>
      </div>
      <div class="mt-3">
        <a
          href="https://www.youtube.com/playlist?list=PLzFo6Bgw8Y95fIYS8WA_zhmYJZZt8a-rT"
          v-if="!this.$route.meta.menu"
          target="_blank"
          class="viber-item-button add-item-button ml-3"
        >
          <img
            width="35"
            height="35"
            src="@/assets/icons/youtube-support.svg"
          />
          YouTube
          <span>"Інструкція"</span>
        </a>
      </div>
    </div>
    <div slot="modal-footer" footer-tag="bootstrap-footer">
      <button
        type="button"
        class="btn btn-filter-transparent"
        @click="$bvModal.hide('add-feedback-popup')"
      >
        Закрити
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AddFeedbackPopup',
  data: () => {
    return {
      form: {
        picture: [],
        urls: []
      }
    };
  },
  methods: {
    onFileChange(e) {
      const selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.form.urls.push(selectedFiles[i]);
      }
      for (let i = 0; i < this.form.urls.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          this.$refs.pic[i].src = reader.result;
          this.form.urls[i] = reader.result;
        };
        reader.readAsDataURL(this.form.urls[i]);
      }
    },
    removeImage(index) {
      this.form.picture.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.modal-add-avatar {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.viber-logo {
  width: 35px;
  height: 35px;
}
.btn-close {
  padding: 0px;
  border: 0;
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  right: 5px;
  top: 0;
}

.btn-close img {
  width: 13px;
  height: 13px;
}

.bootstrap-footer {
  position: static;
  display: flex;
  justify-content: center;
}

.text-center {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
</style>
